<template>
    <div class="apply-dysuccess">
        <div class="success-icon">
            <van-icon name="checked" color="#47C800" size="90"/>
        </div>
         <!-- 未订阅状态 -->
        <template>
            <div class="success-text1">报名成功，是否订阅新的项目信息</div>
            <div class="success-text2">订阅后，如果有适合你的项目，系统会给你发送消息。</div>
            <template>
                <!-- <van-button color="#0060C2" block class="btn">订阅</van-button>
                <div class="success-btn" @click="okClick()">暂不订阅</div> -->
            </template>
            <template>
                <div class="success-btn" @click="okClick()">订阅成功，关闭页面</div>
            </template>
            
        </template>
         <!-- 关注及订阅成功 -->
        <template>
            <!-- <div class="success-text2">报名成功！请等待招募专员的回访</div>
            <div class="success-btn" @click="okClick()">好的</div> -->
        </template>
        <!-- 弹框 -->
        <van-popup v-model="show" class="popup-contianer">
            <div class="code-popup">
                <div class="title">订阅新的项目信息</div>
                <div class="tips">长按识别下方二维码，关注健康随我行V公众号后，即可成功订阅</div>
                <div class="pic">
                    <img src="../../assets/images/jkswxV.png" alt="">
                </div>
                <div class="colse-popup">
                    <span @click="show = false">关闭</span>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { Icon, Button, Popup  } from 'vant';
import {toPath} from '@/utils/common';
export default {
    components:{
        [Icon.name]: Icon,
        [Button.name]: Button,
        [Popup.name]: Popup,
    },
    data() {
        return{
            show: true
        }
    },
    methods: {
        okClick() {
            const { projectId } = this.$route.query
            toPath(this, {path: '/clinicalTrialDeail', query: {id:projectId}}, '', 'replace')
        }
    }
}
</script>
<style lang="less" scoped>
.apply-dysuccess {
    padding: 58px 15px;
    text-align: center;
    // .success-icon {
    //     text-align: center;
    // }
    .success-text1 {
        padding: 50px 45px 0;
        font-weight:600;
        font-size:17px;
        color:#313134;
        line-height: 1.5;
        // text-align: center;
    }
    .success-text2 {
        padding: 13px 45px 40px;
        font-size:16px;
        color:rgba(49,49,52,1);
        line-height: 1.5;
        // text-align: center;
    }
    .success-btn {
        margin-top: 30px;
        font-size:16px;
        font-weight:500;
        color: #0060C2;
        // text-align: center;
    }
    .btn {
        margin: 0 auto;
        width:295px;
        height:50px;
        border-radius:10px;
    }
    .popup-contianer {
        border-radius:14px;
        .code-popup {
            width: 270px;
            padding: 27px 20px 21px;
            
            .title {
                font-size:17px; 
                font-weight:500;
                
            }
            .tips {
                font-size:13px; 
                margin-top: 15px;
                line-height: 1.5;
            }
            .pic {
                height: 150px;
                margin: 15px 0 21px;
                text-align: center;
                img {
                    width: 150px;
                    height: 150px;
                }
            }
            .colse-popup {
                text-align: center;
                font-size:17px;
                span {
                    font-size:17px;
                    font-weight:600;
                    color: #007AFF;
                }
            }
        }
    }
    
}
</style>